import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

export interface Account {
  login: boolean;
  id: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private serverUrl = 'https://licensepass.unit.ne.jp/clientApi';
  private _loginStatus = false;
  id = "";
  password = "";

  constructor(private http: HttpClient) {
  }

  reset(): void {
    this.id = "";
    this.password = "";
  }

  get loginStatus(): boolean {
    return this._loginStatus;
  }

  async login(): Promise<boolean> {
    const res = await this.request('login', { id: this.id, pw: this.password }).catch((e) => {
      console.log(e.reason);
      return false;
    });
    if (res.status) {
      this._loginStatus = true;
      return true;
    }
    console.log( res.reason );
    return false;
  }

  private async request(request: string, data: object = {}): Promise<any> {
    const options = {
      request: request,
      data: data
    }
    // POSTリクエスト等の単純な非同期処理にRxJS処理を書くのは大事過ぎて可読性が悪いと判断し
    // ObservableをPromiseに変換して処理している。
    // firstValueFrom()は、http.postがObservableを返すので、それをPromiseに変換してくれるもの。
    // toPromise()が非推奨になっているのでこの関数を使ってPromiseへの変換を行ってる。
    return await firstValueFrom(this.http.post<any>(this.serverUrl, options)).catch((e) => {
      throw { status: false, reason: `LicensePassport サーバーへの接続で不明なエラーが発生しました。${e}` };
    });
  }
}
